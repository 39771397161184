<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.container-pane
  position: fixed
  // bottom: 0px
  // right: 0px
  // width: calc(100vw - 370px - 0px)
  // height: 50vh
  // overflow: scroll
  // border-color: #555 !important

  top: 0px
  right: 0px
  z-index: 1
  height: 100vh
  width: calc(50vw)
  min-width: 300px


  .th
    vertical-align: middle
.item-history
  border-left: solid 2px #fff
  // font-size: 14px
  font-weight: 500
  // &.active
  //   border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &:hover
    border-left: solid 2px #007bff
    background-color: #fafafa
    cursor: pointer
    .link-open
      opacity: 0.3
  &.active
    border-left: solid 2px #007bff
    // background-color: #fafafa
    background-color: #fff
  .link-open
    opacity: 0
.text-fail
  // font-weight: bold
  color: mix(tomato, #555, 80%)
.detail-body
  cursor: initial
.message-type-SMS
  color: #f0f0f0 !important
  background-color: #3498db !important
  padding: 2px 3px
.message-type-EMAIL
  color: #f0f0f0 !important
  background-color: #1abc9c !important
  padding: 2px 3px
.btn-tag-close
  &:hover
    background-color: rgba(0,0,0,.0)
    svg
      background-color: rgba(0,0,0,.05)
      border-radius: 50%
.hover-action
  .hover-action-visible
    opacity: 0
  &:hover
    .hover-action-visible
      opacity: 1

.query-editor
  // background: #2d2d2d
  // color: #ccc
  background: #f5f2f0

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace
  // font-size: 14px
  line-height: 1.5
  padding: 1rem
  border-radius: 1rem

</style>
<template lang="pug">
//- div.container-pane.shadow.bg-white.border
  //- .container-search-pad
//- div
  modal(name='record' width='500px' :height='`auto`' :scrollable='true')
    .p-4
      h5 예약완료문자
        span.text-muted.ml-2 체크리스트
div.bg-white(style='min-height: 500px')
  //- (style='margin-top: -61px; position: relative; z-index: 1')
  //- div.container-search.bg-white.shadow-sm.pl-2
    router-link(:to='`/property/${property_id}/activity`')
      span.text-dark(style='font-size: 18px; line-height: 50px')
        b-icon-chevron-left
        | 뒤로
    strong.text-secondary.ml-4.mr-2 {{document.name}}
  div(@keyup.esc.stop='$modal.hide("tag")')
    modal.modal-name-tag.rounded(name='tag' transition='tag' :shiftY='0.2' :height='`auto`' :scrollable='true' @keyup.esc.stop='close_tag' @keyup.enter.stop='' @before-close='before_close_tag')
      //- button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("tag")' style='font-size: 1.5rem')
        b-icon-x
      //- div.title.py-3.pl-3 태그 선택
      //- search-tag(:tags='tags' @updated='tag_did_updated' :defaultSelected='current_tag')
  div.p-3.text-center.bg-light(style='color: #888;' v-show='row.deleted_at')
    h5 삭제된 내역입니다.
  modal.py-2(name='add-query' :height='`auto`')
    .p-4(style='font-size: 14px;')
      h3.title 상세 조회하기
      p 보고있는 내용으로 새로운 쿼리를 입력합니다.
      prism-editor(class='query-editor' v-model='query' :highlight='highlighter' line-numbers)
      .mt-2
        //- span.text-muted 클릭하여 파라메터로 넣기
        //- br
        button.btn.btn-default.border.mr-1.mb-1.shadow-sm.p-2(type='button' v-for='col in document.config.cols' v-if='col.format == "text"' v-b-tooltip.hover :title='row.json[col.key]') {{col.label}}
      .pt-4
      label.d-block 쿼리 결과
        button.btn.btn-light.text-primary.ml-2(type='button') 새로고침
      .pt-4
      button.btn.btn-default.text-primary.mr-2(type='button' @click='$modal.hide("add-query")') 닫기
      button.btn.btn-primary(type='button' disabled) 항목 추가하기
  form(@submit.prevent='submit' @keyup.esc.stop='')
    table.table(v-if='row.id')
      tbody
        tr(v-for='(col, $index) in document.config.cols' v-show='(!hide_customer || col.format[0] != `@`)')
          th.bg-light.w-25.text-right(:class='{"border-0":(hide_customer)}' v-if='col.label == "상품상세"')
            span.d-inline.float-right
              dropdown(:active='dropdown_id_active')
                template(slot='toggler')
                  button.btn.btn-default.btn-sm(type='button' style='font-size: 12px; padding: .25rem .5rem;')
                    | {{col.label}}
                    span.badge.badge-info.ml-1(v-show='col.format == "sql" ') LIVE
                dropdown-content
                  .text-center.py-1.text-dark
                    a.link-dropdown.text-left.p-2.d-block(href='#')
                      span.d-block SQL 수정
                    a.link-dropdown.text-left.p-2.d-block(href='#')
                      span.d-block 로그 조회
                    a.link-dropdown.text-left.p-2.d-block(href='#')
                      span.d-block 새고로침
                  //- hr.my-2
          th.bg-light.w-25.text-right(:class='{"border-0":(hide_customer)}' v-else) {{col.label}}
            span.badge.badge-info.ml-1(v-show='col.format == "sql" ') LIVE

          td.hover-action(:class='{"border-0":(hide_customer)}')
            template(v-if='col.format == `text`')
              span {{row.json[col.key]}}
              button.hover-action-visible.btn.btn-sm.btn-default.float-right.py-1.px-1(style='margin: -5px 0 -10px 0;' type='button' @click='$modal.show("add-query")') 조회하기
              //- div.pt-2(v-if='col.label == "product_id"')
                b-tabs.shadow(card)
                  b-tab.p-0(title='상품상세')
                    //- .shadow.mt-2(style='border: solid 2px #ccc;')
                    table.table.mb-0
                      tbody
                        tr.border-0
                          th.border-0.bg-light.w-25.text-right display_name
                          td.border-0.hover-action 당일예약-위례중앙로
                        tr
                          th.bg-light.w-25.text-right created_at
                          td.hover-action 2021-03-01 09:13:20
                        tr
                          th.bg-light.w-25.text-right 담당자
                          td.hover-action 한지훈
                        tr
                          th.bg-light.w-25.text-right 메모
                          td.hover-action
                            textarea.form-control 3월 20일: 휴무
                        tr
                          th
                          td
                            button.btn.btn-primary(type='submit') 저장
                            button.btn.btn-default.text-danger.float-right(type='button') 삭제
                  b-tab.rounded-0(title='상품재고')

                //- button.btn.mr-2.py-2.btn-secondary.rounded-pill.shadow-sm(type='button') 상품상세
                //- button.btn.mr-2.py-2.btn-light.rounded-pill.shadow-sm(type='button') 상품재고
                //- button.btn.mr-2.py-2.btn-default.text-secondary.rounded-pill.float-right(type='button') + 추가
                //- button.hover-action-visible.btn.btn-sm.btn-default.float-right.py-1.px-1(style='margin: -5px 0 -10px 0;' type='button') 추가



              transition-group(type='transition' name='tag')
                div.shadow-lg.rounded.pb-2(:key='show_tag_search' v-if='show_tag_search')
                  //- style='position: absolute; right: 40px; background-color: #fff; width: 500px'
                  button.btn.btn-default.float-right.text-muted.rounded-0.btn-tag-close(type='button' @click='search_tag' style='font-size: 1.5rem; position: relative; top: 100px; margin-top: -100px;')
                    b-icon-x
                  form(@submit.prevent.stop='')
                    search-tag(:tags='tags' @updated='tag_did_updated' :defaultSelected='current_tag')

            template(v-else-if='col.format == `sql`')
              div(v-if='col.result_format == "loading"')
                b-spinner(label='가져오는중' small variant='secondary')
              div(v-if='col.result_format == "value"')
                span.text-muted(style='border-bottom: dashed 1px #ccc') {{col.result_value}}
                span.float-right.text-muted.ml-2 5분전 가져옴
              //- div(style='overflow:hidden' v-if='col.result_format == "rows"')
              //-   button.btn.btn-light.text-info.w-25(type='button') 조회
              div.rounded.border.shadow-sm(style='overflow:hidden' v-if='col.result_format == "rows"')
                table.table.mb-0(style='overflow:hidden')
                  tbody
                    tr
                      th.border-0.bg-light.w-25.text-right name
                      td.border-0.hover-action 당일예약-디너
                    tr
                      th.bg-light.w-25.text-right channel_name
                      td.hover-action DH
                    tr
                      th.bg-light.w-25.text-right allow_cancel
                      td.hover-action false
                    tr
                      th.bg-light.w-25.text-right payment_amount
                      td.hover-action 150000
                    tr
                      th.bg-light.w-25.text-right cancel_fee
                      td.hover-action 10% (3일전 무료)

            template(v-else)
              input.form-control(type='text' v-model='row.json[col.key]' :disabled='row.deleted_at')

        tr
          th.bg-light.w-25.text-right(:class='{"border-0":(hide_customer)}') 메모
          td
            textarea.form-control




        tr
          th
          td
            button.btn.btn-primary(type='submit' :disabled='saving || row.deleted_at') {{saving_text}}
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove' v-show='!row.deleted_at') 삭제
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove_undo' v-show='row.deleted_at') 삭제 복구


</template>

<script>

import Dropdown from "@/components/Dropdown";
import DropdownContent from "@/components/DropdownContent";
import DropdownItem from "@/components/DropdownItem";
import DropdownBlock from "@/components/DropdownBlock";



import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css'; // import syntax highlighting styles
// import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

let col_added = false

export default {
  name: 'customer-record',
  props: ['property_id', 'document', 'tags', 'document_id', 'record_id', 'record_row_id', 'hide_customer'],
  components: {
    Dropdown, DropdownContent, DropdownItem, DropdownBlock,
    PrismEditor
  },
  computed: {

  },
  watch: {
    // '$store.state.documents'() {
    //   this.load()
    // },
    // 'document_id'() {
    //   this.load()
    // },
    'record_row_id'() {
      console.log('rid updated')
      this.load()
    },
  },
  data() {
    return {
      ENV: process.env.NODE_ENV,
      API_URL: process.env.VUE_APP_BASE_API_URL,
      done: false,
      saving: false,
      saving_text: '저장',
      // document: {},
      row: {},

      dropdown_id_active: false,
      dropdown_id_loaded: false,

      group_rows: [],
      group_record_id: null,
      group_record_document: {},

      message_record_rows: [],
      message_record_loaded: false,

      row_tags: [],
      current_tag: null,
      show_tag_search: false,

      col_key_query: {},
      show_query: {
        ready: false,
      },
      query: `SELECT * FROM product_stock_history
WHERE product_id = :product_id AND state = 'IN'
ORDER BY id DESC
LIMIT `,
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    console.log(this.record_row_id)
    if (this.property_id && this.document && this.record_row_id) {
      this.load()
    }
    setTimeout(() => {
      // this.document.config.cols.push({
      //   key: 'd'+Date.now(),
      //   format: 'sql',
      //   label: '최근 상품판매일',
      //   // query: `SELECT * FROM products WHERE id = :product_id`,
      //   result_format: 'loading',
      //   // result_value: '2021-03-21 23:46:01',
      //   show_trigger: 'view',
      // })
      // this.document.config.cols.push({
      //   key: 'd'+Date.now(),
      //   format: 'sql',
      //   label: '최근 상품판매일',
      //   query: `SELECT * FROM products WHERE id = :product_id`,
      //   result_format: 'value',
      //   result_value: '2021-03-21 23:46:01',
      //   show_trigger: 'view',
      // })
      // // this.document.config.cols.push({
      // //   key: 'd'+Date.now(),
      // //   format: 'sql',
      // //   label: '상품재고 현황',
      // //   result_format: 'row',
      // //   show_trigger: 'click',
      // // })
      // this.document.config.cols.push({
      //   key: 'd'+Date.now(),
      //   format: 'sql',
      //   label: '상품상세',
      //   result_format: 'rows',
      //   query: `SELECT * FROM products WHERE id = :product_id`,
      //   show_trigger: 'click',
      // })
      this.$modal.show('add-query')
    }, 1000);
  },
  methods: {
    highlighter(code) {
      // return highlight(code, languages.js); // languages.<insert language> to return html with markup
      return highlight(code, languages.js)
    },
    open_query(col) {
      console.log(col, this.show_query)
      this.show_query[col.key] = true
    },
    close_tag() {
      console.log(1)
    },
    before_close_tag() {
      // 전체 태그 업데이트
      setTimeout(() => {
        this.$emit('tag_updated')
      }, 1000)
    },
    select_tag(tag) {
      if (this.current_tag && this.current_tag.id == tag.id) {
        this.show_tag_search = false
        return
      }

      this.current_tag = tag
      // this.$modal.show('tag')
      this.show_tag_search = false
      setTimeout(() => {
        this.show_tag_search = true
      }, 300);
    },
    async remove_tag(tag, silent = false) {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      if(!silent && !confirm(`[${tag.name}] 태그를 삭제할까요?`)) return false
      const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.row.id}/tags/actions/remove`, {
        name: tag.name,
      })
      if (r2?.data?.message != 'ok') throw new Error(r2?.data?.message || '태그 저장실패')
      this.load_tags()
      this.$emit('tag_updated')

      // setTimeout(() => {
      //   this.$emit('tag_updated')
      // }, 1000)
    },
    search_tag() {
      // this.$modal.show('tag')
      this.show_tag_search = !this.show_tag_search
    },
    async tag_did_updated(tag) {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      console.log('tag>', tag)
      try {
        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.row.id}/tags/actions/add`, {
          name: tag.name,
        })
        if (r2?.data?.message != 'ok') throw new Error(r2?.data?.message || '태그 저장실패')

        if (this.current_tag) {
          // await this.remove_tag(this.current_tag, true)
          this.current_tag = null
        }
        this.load_tags()
        this.$emit('tag_updated')
        // this.$modal.hide('tag')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }

    },
    async history_action(action, row) {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      if (action == 'retry') {
        if(!confirm('발송실패한 메시지만 재발송 요청 가능합니다. 진행할까요?')) return false
        try {
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document.id}/messages/${row.message_id}/records/${row.id}/actions/retry`)
          if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '실패')
          setTimeout(() => {
            this.load_message_record()
          }, 300);
          this.$modal.show('dialog', {
            title: '알림',
            text: '요청했습니다.'
          })
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
      }
    },
    open_group_record(row) {
      this.group_record_document = row.document
      this.group_record_id = row.external_id

      // close
      this.dropdown_id_active = Date.now()
    },
    async load_group() {
      console.log('>>>>>>>>> loadgroup')
      if (this.dropdown_id_loaded) return

      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/group-records/${decodeURIComponent(this.record_id)}`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객 데이터 가져오기 실패')
        this.group_rows = r.data.rows.map(e => {
          e.document = this.$store.state.documents_by_id[e.document_id]
          e.json = JSON.parse(e.json)
          return e
        })

        setTimeout(() => {
          this.dropdown_id_loaded = true
        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    update_external_id() {
      console.log(11)
      this.dropdown_id_active = Date.now()
    },
    async load_tags() {
      const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.row.id}/tags`)
      if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '고객 태그 가져오기 실패')
      this.row_tags = r.data.rows

      // this.search_tag()
    },
    async load() {
      // this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      // if (!this.document.id) {
      //   throw new Error('해당 목록을 찾지 못했습니다.')
      // }
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}`, {

        })
        // if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '고객 데이터 가져오기 실패')
        this.row = r.data.row

        await this.load_tags()

        this.done = true
        this.$emit('loaded')

        this.load_message_record()

        for(const col of this.document.config.cols) {
          this.show_query[col.key] = false
        }
        this.show_query.ready = true
        this.col_key_query['product_id'] = {
          rows: []
        }
        // console.log(this.col_key_query)
        this.col_key_query['product_id'].rows.push({
          query: `SELECT * FROM products WHERE id = :product_id`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_message_record() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/record-ids/${this.row.id}/message-records`, {

        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '내역 가져오기 실패')
        this.message_record_rows = r.data.rows.map(e => {
          e.opened = false
          return e
        })

        this.message_record_loaded = true
        setTimeout(() => {
          console.log(this.$refs.message_history)
          const items = this.$refs.message_history.querySelectorAll('.item-history')
          if (items.length > 3) {
            items[items.length-1].scrollIntoView({block: 'nearest'})
          }
        }, 100);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      this.saving = true
      this.saving_text = '저장 중'
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}`, {
          row: this.row.json,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)

        const external_id = r.data.external_id

        // if (this.row.external_id != r.data.external_id) {
        //   if (!this.hide_customer) {
        //     this.$router.push({
        //       path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}/${r.data.id}/${encodeURIComponent(r.data.external_id)}`,
        //     })
        //   }
        // } else {
        //   this.load()
        // }
        this.load()

        this.saving = false
        this.saving_text = '저장됨'

        // if (!this.hide_customer) {
        //   this.$emit('updated')
        // }
        this.$emit('updated', {
          external_id,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async remove() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      try {
        if(!confirm('삭제하시겠습니까?')) return false

        const r = await this.$http.delete(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}`)
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async remove_undo() {
      if (!this.row.deleted_at) {
        console.log('cannot recover undeleted row')
        return
      }
      try {
        if(!confirm('삭제된 내역을 복구 하시겠습니까?')) return false

        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/row/${this.record_row_id}/actions/undo_delete`)
        if (r?.data?.message != 'ok') throw new Error('복구 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '복구했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
  },
}
</script>
