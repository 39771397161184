<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.container-filter
  font-size: 12px
  font-weight: 600
  line-height: 1.7rem
  .link
    color: darken(#f8f9fa, 40%)
.container-search
  line-height: 1.7rem
  font-size: 12px
  .link
.container-inactive-top
  position: relative
  top: -6rem
  height: 6rem
  background-color: rgba(248,249,250,.7)
.required
  color: tomato
// .container-modal-backdrop
//   // background-color: rgba(248,249,250,.5)
//   background-color: rgba(0,0,0,.2)
//   position: fixed
//   top: 0px
//   left: 0px
//   width: 100%
//   height: 100%
//   z-index: 2000
// .container-modal
//   position: fixed
//   z-index: 2001
//   top: 3rem
//   width: 760px
//   left: calc(50% - 760px/2)
//   .close
//     font-size: 24px
//   .body
//   .text-description
//     color: darken(#f8f9fa, 50%)
//     font-size: 12px
//     font-weight: 600
.container-table
  font-size: 12px
  // overflow: scroll
  // position: relative
  // height: calc(100% - 300px)
  // height: 300px
  .sticky-header
    position: sticky
    top: -1px
    // left: 0px
    // display: inline-block
    background-color: #fff
    z-index: 1000
    // border-bottom: solid 2px #ccc
    outline: solid 1px #ccc
    border-bottom: 0
    // font-weight: 800
    color: #333 !important
  .link-view-out
    // text-decoration: none
    // &.router-link-active
    //   background-color: #f0f0f0
    .link-view
      color: #007bff
      border-bottom: solid 0.05em #007bff
      font-weight: bold
      transition: color linear 100ms, border linear 100ms
    &:hover
      // background-color: #fafafa
      .link-view
        color: #333
        border-color: lighten(#333, 30%)
        text-decoration: none
    &:visited
      .link-view
        color: #555
        border-bottom: solid 0.05em lighten(#555, 30%)
      &:hover
        .link-view
          color: #333
  // .link-view
  //   // text-decoration: underline
  //   color: #007bff
  //   border-bottom: solid 0.05em #007bff
  //   font-weight: bold
  //   transition: color linear 100ms, border linear 100ms
  //   &:hover
  //     color: #333
  //     border-color: lighten(#333, 30%)
  //     text-decoration: none
  //   &:visited
  //     color: #555
  //     border-bottom: solid 0.05em lighten(#555, 30%)
  //     &:hover
  //       color: #333
  tbody
    tr
      td
        color: #555

      &.hover
        cursor: pointer
        .hover-visible
          visibility: hidden
        &:hover
          box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
          .hover-visible
            visibility: visible

      &.row-selected
        background-color: mix(#007bff, #f0f0f0, 8%)

        td
          border-top-color: mix(#007bff, #f0f0f0, 20%)

        .link-view-out
          &:hover
            background-color: mix(#007bff, #f0f0f0, 14%)
            text-decoration: underline

          &.router-link-active
            background-color: mix(#007bff, #f0f0f0, 18%)

.text-none
  color: #999
  // &:hover
  //   color: #777
.item-filter
  .item-clear
    // display: none
    position: relative
    top: -300px
  &:hover
    .item-clear
      top: 0px
</style>
<template lang="pug">
div
  router-view(
    v-if='fullpage_record_id'
    :property_id='property_id'
    :document='document'
    :tags='tags'
    :document_id='document_id'
    :record_id='record_id'
    :record_row_id='record_row_id'

    :fullpage_record_id='fullpage_record_id'
    @before-close='record_before_close'
  )
  .container-table
    //- (:class='{done:done}' v-if='document && document.id')
    //- form.form-inline
    //-   input.form-control(type='text' placeholder='검색어 ')
    div.mx-2.mb-1(style='min-height: 50px')
      template(v-if='document && document.id')
        span.float-right(style='width: 200px; position: relative; top: -10px')
          select.form-control.form-control-sm.float-left(v-model='sortby' @change='change_sortby' style='width: 130px')
            option(value='created_at') 생성일
            option(value='updated_at') 수정일
            option(v-for='col in document.config.cols' :value='col.key') {{col.label}}
          button.btn.btn-light.float-right(type='button' @click='change_sort')
            b-icon-sort-down(v-show='sort == "ASC"')
            b-icon-sort-up(v-show='sort == "DESC"')
        //- h5 {{filter_changed}}
        span.mr-1.mb-1 {{rows.length}}건
          span.text-muted(v-if='rows.length != rows_count')  (총 {{rows_count}}건)
        //- .px-2.py-1.shadow-sm.border.text-secondary.mr-1.mb-1
        .btn-group.mr-1.mb-1(v-for='item in filters')
          button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='open_selected_filter_modal(item)')
            //- b-icon-person-lines-fill(v-if='item.prekey == "search.text"')
            b-icon-person-fill(v-if='item.prekey == "search.customer"')
            //- b-icon-list(v-if='item.prekey == "search.field"')
            span(v-if='item.prekey == "search.field"') {{item.col.label}}:&nbsp;
            span(v-if='item.key == "search.field.value_not_contain"') (제외)&nbsp;
            span(v-if='item.key == "search.field.value_is_empty"') (비어있음)&nbsp;
            span(v-if='item.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
            //- | {{item}}
            span(v-if='item.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
            span(v-if='item.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
            span(v-if='item.prekey == "search.row"') {{item.value.length}}건&nbsp;
            span(v-else) {{item.value}}
            b-icon-x.item-clear(@click.prevent.stop='clear_filter(item)')
          template(v-for='subitem in item.sub_or')
            button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='open_selected_filter_modal(subitem)')
              //- span {{subitem}}
              //- b-icon-person-lines-fill(v-if='subitem.prekey == "search.text"')
              b-icon-person-fill(v-if='subitem.prekey == "search.customer"')
              //- b-icon-list(v-if='subitem.prekey == "search.field"')
              span(v-if='subitem.prekey == "search.field"') {{subitem.col.label}}:&nbsp;
              span(v-if='subitem.key == "search.field.value_not_contain"') (제외)&nbsp;
              span(v-if='subitem.key == "search.field.value_is_empty"') (비어있음)&nbsp;
              span(v-if='subitem.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
              //- | {{subitem}}
              span(v-if='subitem.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
              span(v-if='subitem.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
              span(v-if='subitem.prekey == "search.row"') {{subitem.value.length}}건&nbsp;
              span(v-if='!subitem.key') (없음)
              span(v-else) {{subitem.value}}
              b-icon-x.item-clear(@click.prevent.stop='clear_filter(subitem)')
          button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='or_filter(item)') OR
            //- b-icon-plus

        button.btn.btn-default.btn-sm.px-2.py-1.text-muted.mr-4.mb-1(type='button' style='border: dashed 1px #ccc' @click='open_filter_modal')
          b-icon-plus
          | 검색조건 AND
        button.btn.btn-default.btn-sm.px-2.py-1.text-muted.mr-1.mb-1(v-if='filters.length' type='button' style='border: dashed 1px #ccc' @click='copy_filter_url')
          b-icon-link
          |  링크 복사
        button.btn.btn-default.btn-sm.px-2.py-1.text-primary.mr-1.mb-1(v-if='filters.length && filter_changed' type='button' style='border: dashed 1px #ccc' @click='save_filter')
          b-icon-list-ul
          |  {{filters.length}}개 필터 저장
        button.btn.btn-default.btn-sm.px-2.py-1.text-muted.ml-3.mr-1.mb-1(v-if='filters.length || rows_selected_count' type='button' style='border: dashed 1px #ccc' @click='send_message_filtered')
          small: b-icon-lightning-fill(style='color: #f1c40f')
          |  메시지보내기

        span.btn.btn-sm.px-2.py-1.text-primary.mb-1(v-if='rows_selected_count') {{rows_selected_count}}개 선택됨
        .btn-group.mb-1(v-if='rows_selected_count')
          //- button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.text-secondary(type='button' style='border: dashed 1px #ccc' @click='open_filter_modal')
          button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='filter_include_selected')
            | 필터에 추가
          button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='filter_exclude_selected')
            | 제외
          button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='tag_add_selected')
            | 태그추가
          button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='tag_remove_selected')
            | 태그삭제
          //- button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary.mr-4(type='button' @click='select_all') 모두선택
          //- button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary.mr-4(type='button' @click='deselect_all') 선택해제
        //- button.btn.btn-default.btn-sm.px-2.py-1.text-muted.ml-3.mr-1(v-if='filters.length && saved_filter_id && filter_changed' type='button' style='border: dashed 1px #ccc' @click='save_filter_for_message')
          small: b-icon-lightning-fill(style='color: #f1c40f')
          |  보고있는 필터로 메시지보내기

    modal.modal-name-filter.rounded(name='filter' transition='filter' :shiftY='0.2' :height='`auto`' :scrollable='true')
      search-filter(:document='document' @updated='filter_did_updated' :default-selected='selected_filter')

    modal.modal-name-tag.rounded(name='tag' transition='tag' :shiftY='0.2' :height='`auto`' :scrollable='true')
      //- search-filter(:document='document' @updated='filter_did_updated' :default-selected='selected_filter')
      search-tag(:tags='tags' @updated='tag_did_updated')
    //- pre {{tags}}
    table.table
      //- (:style='{width:`${width}px`}')
      thead
        tr
          //- th.sticky-header.shadow-sm(v-for='col in columns' :style='{width:`${col.width}px`}')
          th(style='width: 50px' @click.prevent.stop='select_all')
            label.m-0(@click.prevent.stop='select_all')
              b-form-checkbox.rounded-0(:checked='selected_all' value='true' unchecked-value='false')
          th(style='width: 300px' v-if='!cols_ready || cols_primary.length') 제목
          th 내용
          th
          th
            span(v-if='sortby == "created_at"') 생성일
            span(v-else-if='sortby == "updated_at"') 수정일
            span(v-else-if='cols_by_key[sortby] && cols_by_key[sortby].position != "secondary"') {{cols_by_key[sortby].label}}
          //- th(v-for='(col, $index) in document.config.cols' v-if='col.visible_at_table == `Y`' style='min-width: 150px')
            span {{col.label}}
            //- span(v-if='col.label != `+`') {{col.label}}
            //- span(v-else style='opacity: 0.5')
              a.text-muted(href='#')
                b-icon-plus
                | 맞춤항목 추가
      tbody
        tr.hover(v-for='row in rows' :class='{"row-selected": row.is_selected}' @click.keydown.meta.prevent.stop='open_record_new(row)' @click.keydown.ctrl.prevent.stop='open_record_new(row)' @click.exact='open_record(row)' )
          td(@click.prevent.stop='select_row(row)')
            //- input(type='checkbox')
            b-form-checkbox(v-model='row.is_selected' value='true' unchecked-value='false' @click.prevent.stop='')
          td.p-0(style='height: 100%' v-if='!cols_ready || cols_primary.length')
            router-link.d-block.link-view-out.py-3.pl-3(style='font-weight: 500;' :to='`/property/${property_id}/customer/${document_id}${saved_filter_prefix}/${$options.filters.encodeText(document.name)}/${row.id}/${encodeURIComponent(row.external_id)}`' @click.native.stop='')
              span(v-for='(colkey, $index) in cols_primary')
                span.mr-2(
                  v-if='$index === 0'
                  :class='{"text-none": (!row.json[colkey])}'
                ) {{row.json[colkey] || '(비어있음)'}}
                span.mr-2(v-else) {{row.json[colkey]}}
              span.d-inline-block.pt-1(v-if='tags_count && tags_by_record_id')
                span.btn.btn-light.btn-sm.d-inline-block.bg-light.rounded.text-muted.mr-1.mb-1.p-1(v-for='tag in tags_by_record_id[row.id]' @click.prevent.stop='filter_tag(tag)') {{tag.name}}
          td
            .row
              //- .col-sm-12.col-3.col-lg(v-for='col in cols_secondary')
                span.text-muted.d-block {{col.label}}
                strong.text-dark {{row.json[col.key] || '-'}}
              .col-3.col-lg(v-for='col in cols_secondary' style='min-width: 120px; padding-bottom: 0.5rem')
                strong.d-block {{col.label}}
                span.text-muted.text-break {{row.json[col.key] || '-'}}
            //- span(v-for='col in cols_secondary')
              strong.d-block {{col.label}}
              small.text-muted {{row.json[col.key] || '-'}}
          td.text-right
            router-link.hover-visible.btn.btn-default.btn-sm.text-secondary(:to='`/property/${property_id}/customer/group/${encodeURIComponent(row.external_id)}`' @click.native.stop='')
              //- b-icon-link.mr-1
              small.mr-2: b-icon-box-arrow-up-right
              | 연결된 레코드
          td
            span(v-if='sortby == "created_at"') {{row.created_at | datetime}}
            span(v-else-if='sortby == "updated_at"') {{row.updated_at | datetime}}
            span(v-else-if='cols_by_key[sortby] && cols_by_key[sortby].position != "secondary"') {{row.json[sortby] }}

              //- span.link-view {{row.json[col.key] || '(조회)'}}
          //- td {{row.json['3']}}
          //- td(v-for='(col, $index) in document.config.cols' v-if='col.visible_at_table == `Y`')
            span(v-if='$index != 0') {{row.json[col.key] || '-'}}
            span(v-else)
              router-link.d-block.link-view-out(:to='`/property/${property_id}/customer/${document_id}/record/${row.external_id}`')
                span.link-view {{row.json[col.key] || '(조회)'}}
        //- tr(v-for='i in fills')
          td value value value
            //- br
            //- | value
            //- br
            //- | value
          td value
          td value
          //- td value
          //- td value
          //- td value
          //- td value
          //- td value
    //- h1 {{document.config.cols.length}}
    //- .container-inactive-top(v-if='document.id && document.config.cols.length == 0')
    .row.justify-content-center.align-items-center(v-if='document.id && +rows_count === 0')
      .col-6.async(:class='{done:(done)}')
        .mt-4
        //- .text-center
        h5.title 고객 목록 만들기
        p 메시지 발송 및 고객내역 조회를 위해 최소 1개 이상의 목록이 필요합니다.
        //- pre {{document}}
        br
        router-link.btn.btn-default.text-dark.btn-lg.btn-block.text-left.border.shadow-sm.mr-2(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}/start-from-scratch`')
          b-icon-textarea-t.text-info.mr-3(style='opacity: .5')
          | 수기 입력하기
        router-link.btn.btn-default.text-dark.btn-lg.btn-block.text-left.border.shadow-sm.mr-2(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}/start-from-excel-import`')
          b-icon-file-earmark-ruled.text-success.mr-3(style='opacity: .5')
          | 엑셀 복사/붙여넣기
        router-link.btn.btn-default.text-dark.btn-lg.btn-block.text-left.border.shadow-sm.mr-2(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}/start-from-db`')
          b-icon-server.text-warning.mr-3(style='opacity: .5')
          | DB 가져오기
        //- a.btn.btn-default.text-dark.btn-lg.btn-block.text-left.border.shadow-sm.mr-2(href='#' @click.prevent='$modal.show("dialog", {"title":"알림","text":"준비중입니다."})')
          b-icon-server.text-warning.mr-3(style='opacity: .5')
          | DB 가져오기
          small.text-muted.float-right(style='font-size: 12px; line-height: 1.75rem; opacity: .5') 준비중
        //- router-link.btn.btn-light.mr-2(:to='`/property/${property_id}/customer/${document_id}/start-from-source/`') DB 가져오기

        div(style='padding-top: 150px')

    //- customer-record(
    //-   :property_id='property_id'
    //-   :document='document'
    //-   :document_id='document_id'
    //-   :record_id='record_id'
    //- )
    modal.py-2(name='save-filter' width='700px' :height='`auto`' :scrollable='true'  :focusTrap='true')
      button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("save-filter")' style='font-size: 1.5rem')
        b-icon-x
      .p-4
        h3.title.py-3 필터 저장하기


        form(@submit.prevent='submit_filter')
          .mb-4
          .form-group
            label.d-block 이름
            input.form-control(type='text' v-model='form_filter.name' autofocus)
            p.text-muted 필터목록에 이름을 보여줍니다.
          .form-group
            label.d-block 표시 설정
            b-form-checkbox.rounded-0(v-model='form_filter.is_shared' value='Y' unchecked-value='N')
              //- | {{col.description}}
              p: small.text-muted 다른 사람에게 이 필터를 보여줍니다.
            //- input.form-control(type='text' v-model='')
          .form-group.text-right
            button.btn.btn-primary(type='submit') 저장

    modal.py-2(name='edit-filter' width='700px' :height='`auto`' :scrollable='true' :focusTrap='true')
      button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("edit-filter")' style='font-size: 1.5rem')
        b-icon-x
      .p-4
        h3.title.py-3 필터 수정하기


        form(@submit.prevent='edit_filter')
          .mb-4
          .form-group
            label.d-block 이름
            input.form-control(type='text' v-model='saved_filter.name' autofocus)
            p.text-muted 필터목록에 이름을 보여줍니다.
          .form-group
            label.d-block 표시 설정
            b-form-checkbox.rounded-0(v-model='saved_filter.is_shared' value='Y' unchecked-value='N')
              p: small.text-muted 다른 사람에게 이 필터를 보여줍니다.
          .form-group.text-right
            button.btn.btn-light.text-primary.mr-2(type='button' @click='save_as_filter') 다른 이름으로 저장
            button.btn.btn-primary(type='submit') 저장

    modal.py-2(name='record' width='700px' :height='`auto`' :scrollable='true'
      transition='record'
      @before-close='record_before_close'
    )
      button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("record")' style='font-size: 1.5rem')
        b-icon-x
      .p-4(:style='{minHeight: min_height}' v-if='record_row_id' ref='record_container')
        h5 {{document.name}}
          span.text-muted.ml-2 조회

          //- span.d-inline.float-right
            dropdown(:active='dropdown_group_record_active')
              template(slot='toggler')
                button.btn.btn-default.btn-sm(type='button' style='font-size: 12px; padding: .25rem .5rem; color: #999')
                  | 연결된 레코드
                  b-icon-link45deg
              dropdown-content
                //- dropdown-block.mt-1
                  input.form-control.form-control-sm.form-dropdown(type='text' value='목록 이름')
                a.link-dropdown.text-left.p-2.d-block(href='#' @click.prevent='update_external_id(1)') @고객아이디
                //- hr.my-2

        customer-record(
          :property_id='property_id'
          :document='document'
          :tags='tags'
          :document_id='document_id'
          :record_id='record_id'
          :record_row_id='record_row_id'
          @loaded='record_did_loaded'
          @updated='record_did_updated'
          @tag_updated='record_tag_did_updated'
        )
        //- customer-row-record(
        //-   :property_id='property_id'
        //-   :document='document'
        //-   :tags='tags'
        //-   :document_id='document_id'
        //-   :record_id='record_id'
        //-   :record_row_id='record_row_id'
        //-   @loaded='record_did_loaded'
        //-   @updated='record_did_updated'
        //-   @tag_updated='record_tag_did_updated'
        //- )



  //- .container-filter.p-2
    //- a.link.btn.btn-default.text-secondary(href='#') 전체 (0)
    a.link.btn.btn-light.text-primary.mr-1(href='#') 최신순
    a.link.btn.btn-default.text-secondary.mr-1(href='#') 주문대기
    //- a.link.ml-3.btn.text-secondary(href='#') 새 필터 만들기
    button.btn.btn-default.link-search.text-primary.ml-2(type='button') 새 필터 만들기
  //- .container-search.p-2.shadow-sm
    form.form-inline.d-inline
      input.form-control.form-control-sm.rounded.bg-light(type='search' style='width: 300px')
      button.btn.btn-default.link-search.text-primary.ml-2(type='button') 검색하기
      button.btn.btn-default.link-search.text-primary.ml-2(type='button') 정렬


    router-link.link.ml-3.btn.btn-default.text-primary.float-right(:to='`/property/${property_id}/customer/${document_id}/outreach`') 메시지 보내기
    router-link.link.ml-3.btn.btn-default.text-primary.float-right(:to='`/property/${property_id}/customer/${document_id}/start-from-scratch`') 새 고객 추가하기


</template>

<script>

import CustomerRecord from '@/components/CustomerRecord'
import CustomerRowRecord from '@/components/CustomerRowRecord'

import Dropdown from "@/components/Dropdown";
import DropdownContent from "@/components/DropdownContent";
import DropdownItem from "@/components/DropdownItem";
import DropdownBlock from "@/components/DropdownBlock";
import SearchFilter from "@/components/SearchFilter";
import SearchTag from "@/components/SearchTag";

import {uniqBy} from "lodash"

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'record_row_id', 'saved_filter_id'],
  components: {
    CustomerRecord,
    CustomerRowRecord,
    Dropdown, DropdownContent, DropdownItem, DropdownBlock,
    SearchFilter, SearchTag,
  },
  computed: {
    saved_filter_prefix() {
      return this.saved_filter_id ? '-'+this.saved_filter_id : ''
    },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
    'record_row_id'() {
      this.open_modal()
    },
  },
  data() {
    return {
      done: false,
      adding: false,
      document: {},
      rows: [],
      rows_count: 0,
      rows_selected_count: 0,
      fills: Array.from('00000000000000000000000'),

      record_loaded: false,

      dropdown_group_record_active: false,

      filters: [],
      selected_filter: null,

      saved_filter: {},
      form_filter: {
        name: '',
        is_shared: 'N',
      },
      filter_changed: false,

      tags: [],
      tags_by_record_id: {},
      tags_count: null,

      min_height: '90vh',
      should_open_new_window: false,
      selected_all: false,

      sort: 'DESC',
      sortby: 'created_at',
      cols_by_key: {},
      cols_by_key_loaded: false,

      tag_selected_action: '',

      fullpage_record_id: null,
      scroll_stop: false,

      cols_ready: false,
      cols_primary: [],
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
    setTimeout(() => {
      // this.filter_did_updated({ key: 'search.customer.value', prekey: 'search.customer', value: 'tim' })
      // this.save_filter()
    }, 300);
  },
  methods: {
    tag_add_selected() {
      this.tag_selected_action = 'add'
      this.$modal.show('tag')
    },
    tag_remove_selected() {
      this.tag_selected_action = 'remove'
      this.$modal.show('tag')
    },
    async add_tags(ids, name, i = 0) {
      const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/tags/actions/add`, {
        record_ids: ids,
        name,
      })
      if (r2?.data?.message != 'ok') throw new Error(r2?.data?.message || '태그 추가실패')
      const h = this.$createElement
      const _ids = ids
      const _tag_name = name
      const $btn = h('button', {
        attrs: { class: 'btn btn-light ml-2' },
        on: { click: () => {
          // console.log('undo', _ids, _tag_name)
          this.remove_tags(_ids, _tag_name, ++i)
        }},
      }, '되돌리기')
      const el = []
      if (i > 0) {
        el.push(`#${name} 다시 추가했습니다. (되돌리기 완료)`)
      } else {
        el.push(`#${name} 추가했습니다.`, $btn)
      }

      this.$bvToast.toast(el, {
        title: `알림`,
        variant: 'light',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 5000,
      })
      this.load_tags()
    },
    async remove_tags(ids, name, i = 0) {
      const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/tags/actions/remove`, {
        record_ids: ids,
        name,
      })
      if (r2?.data?.message != 'ok') throw new Error(r2?.data?.message || '태그 삭제실패')
      const h = this.$createElement
      const _ids = ids
      const _tag_name = name
      const $btn = h('button', {
        attrs: { class: 'btn btn-light ml-2' },
        on: { click: () => {
          // console.log('undo', _ids, _tag_name)
          this.add_tags(_ids, _tag_name, ++i)
        }},
      }, '되돌리기')
      const el = []
      if (i > 0) {
        el.push(`#${name} 다시 삭제했습니다. (되돌리기 완료)`)
      } else {
        el.push(`#${name} 삭제했습니다.`, $btn)
      }

      this.$bvToast.toast(el, {
        title: `알림`,
        variant: 'light',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 5000,
      })
      this.load_tags()
    },
    async tag_did_updated(tag) {
      console.log('tag>', tag)
      try {
        const ids = this.rows.filter(e => e.is_selected).map(e => e.id)

        if (this.tag_selected_action == 'add') {
          await this.add_tags(ids, tag.name)
        } else if (this.tag_selected_action == 'remove') {
          await this.remove_tags(ids, tag.name)
        } else {
          console.log('invalid tag_selected_action')
        }

        this.$modal.hide('tag')

        // if (this.current_tag) {
        //   // await this.remove_tag(this.current_tag, true)
        //   this.current_tag = null
        // }
        // this.load_tags()
        // this.$emit('tag_updated')
        // this.$modal.hide('tag')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }

    },
    change_sort() {
      this.sort = {
        'ASC': 'DESC',
        'DESC': 'ASC',
      }[this.sort]
      this.load()

      const prev = '' + window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`]
      const next = JSON.stringify({ sort: this.sort, sortby: this.sortby })
      if (prev != next) {
        window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`] = next
      }
    },
    change_sortby() {
      this.load()

      const prev = '' + window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`]
      const next = JSON.stringify({ sort: this.sort, sortby: this.sortby })
      if (prev != next) {
        window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`] = next
      }
    },
    open_record_new(row) {
      window.open(`/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeText(this.document.name)}/${row.id}/${encodeURIComponent(row.external_id)}`, '_blank')
      return false
    },
    open_record(row) {
      this.$router.push({
        path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeText(this.document.name)}/${row.id}/${encodeURIComponent(row.external_id)}`,
      })
    },
    filter_tag(tag) {
      alert('선택한 태그를 필터에 추가합니다.')
    },
    drag_start(e) {
      console.log(e)
    },
    drag_end(e) {
      console.log(e)
    },
    filter_include_selected() {
      const ids = this.rows.filter(e => e.is_selected).map(e => e.id)
      this.filter_did_updated({
        ts: Date.now(),
        key: 'search.row.in_ids',
        value: ids,
        prekey: 'search.row',
      })
    },
    filter_exclude_selected() {
      const ids = this.rows.filter(e => e.is_selected).map(e => e.id)
      this.filter_did_updated({
        ts: Date.now(),
        key: 'search.row.not_in_ids',
        value: ids,
        prekey: 'search.row',
      })
    },
    select_all() {
      console.log(11)
      if (this.rows_selected_count == this.rows.length) {
        this.rows.forEach(e => e.is_selected = false)
      } else {
        this.rows.forEach(e => e.is_selected = true)
      }
      this.rows_selected_count = this.rows.filter(e => e.is_selected).length
      this.selected_all = this.rows_selected_count == this.rows.length
    },
    // deselect_all() {
    //   this.rows.forEach(e => e.is_selected = false)
    // },
    select_row(row) {
      row.is_selected = !row.is_selected
      this.rows_selected_count = this.rows.filter(e => e.is_selected).length
      this.selected_all = this.rows_selected_count == this.rows.length
      return
    },
    save_filter() {
      if (this.saved_filter.id) {
        this.$modal.show('edit-filter')
      } else {
        this.$modal.show('save-filter')
      }
    },
    async save_as_filter(slient = false) {
      this.form_filter.name = this.saved_filter.name
      this.form_filter.is_shared = this.saved_filter.is_shared
      return await this.submit_filter(slient)
    },
    async submit_filter(slient = false) {
      try {
        // save
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters`, {
          filters: JSON.stringify(this.filters),
          is_listed: this.form_filter.is_listed || 'Y',
          is_shared: this.form_filter.is_shared,
          name: this.form_filter.name,
        })
        if (r?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        // get id
        const id = r.data.id

        this.filter_changed = false

        if (slient === true) {

        } else {
          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.document_id}-${id}/${this.$options.filters.encodeReadableText(this.document.name)}`,
          })

          this.$modal.show('dialog', {
            title: '알림',
            text: '추가했습니다.',
          })
        }

        this.$modal.hide('save-filter')

        return id

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }

    },
    async edit_filter() {
      try {
        // save
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters/${this.saved_filter.id}`, {
          filters: JSON.stringify(this.filters),
          is_listed: 'Y',
          is_shared: this.saved_filter.is_shared,
          name: this.saved_filter.name,
        })
        if (r?.data?.message != 'ok') throw new Error('수정 실패')

        this.$modal.show('dialog', {
          title: '알림',
          text: '수정했습니다.',
        })

        this.$modal.hide('edit-filter')

        this.$emit('filter_updated', this.saved_filter)

        this.filter_changed = false

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }

    },
    async copy_filter_url() {
      try {
        let id
        if (this.saved_filter_id && this.saved_filter.id) {
          id = this.saved_filter_id
        } else {
          // save
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters`, {
            filters: JSON.stringify(this.filters),
            is_shared: 'Y',
          })
          if (r?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
          // get id
          id = r.data.id
        }

        // send to clipboard
        const copyToClipboard = (text) => {
          if (window.clipboardData && window.clipboardData.setData) {
              // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
              return window.clipboardData.setData("Text", text);

          }
          else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
              var textarea = document.createElement("textarea");
              textarea.textContent = text;
              textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
              document.body.appendChild(textarea);
              textarea.select();
              try {
                  return document.execCommand("copy");  // Security exception may be thrown by some browsers.
              }
              catch (ex) {
                  console.warn("Copy to clipboard failed.", ex);
                  return false;
              }
              finally {
                  document.body.removeChild(textarea);
              }
          }
        }
        copyToClipboard(`${location.href.replace(location.pathname, '')}/property/${this.property_id}/customer/${this.document_id}-${id}/${this.$options.filters.encodeReadableText(this.document.name)}`)
        this.$bvToast.toast(`복사했습니다.`, {
          title: `알림`,
          variant: 'default',
          solid: true,
          toaster: 'b-toaster-bottom-right',
        })
        // this.$modal.show('dialog', {
        //   title: '알림',
        //   text: '.',
        // })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async send_message_filtered() {
      try {
        if (this.rows_selected_count) {
          this.filter_include_selected()
          // if (confirm(`${this.rows_selected_count}개 선택된 고객을 필터에 추가하고 메시지를 보낼까요?`)) {
        }
        let id

        // save
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters`, {
          name: 'send message',
          filters: JSON.stringify(this.filters),
        })
        if (r?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        // get id
        id = r.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeReadableText(this.document.name)}/outreach`,
          query: {
            fid: id,
          },
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async save_filter_for_message() {
      try {
        this.form_filter.name = ''
        this.form_filter.is_shared = 'N'
        this.form_filter.is_listed = 'N'
        const new_id = await this.submit_filter(true)

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}-${new_id}/${this.$options.filters.encodeReadableText(this.document.name)}/outreach`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    // [{"key":"search.field.value","prekey":"search.field","value":"가이드","col":{"key":"d14","label":"주문"},"ts":1617889170994}]
    clear_filter(item) {
      if (item.parent_ts) {
        this.filters = this.filters.filter(e => {
          if (+e.ts != +item.parent_ts) return true

          if (e.sub_or && e.sub_or.length) {
            e.sub_or = e.sub_or.filter(l => {
              return +l.ts != item.ts
            })
          }
          return true
        })
      } else {
        this.filters = this.filters.filter(e => {
          return +e.ts != +item.ts
        })
      }
      this.selected_filter = null
      const prev = '' + window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`]
      const next = JSON.stringify(this.filters)
      if (prev != next) {
        this.filter_changed = true
        window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`] = next
      }
      this.load()
      return
    },
    or_filter(item) {
      // const subitem = Object.assign({}, item, {
      //   sub_or: undefined,
      //   parent_ts: item.ts,
      //   ts: Date.now(),
      //   value: '',
      // })
      const subitem = Object.assign({}, {
        sub_or: undefined,
        parent_ts: item.ts,
        ts: Date.now(),
      })
      this.filters = this.filters.filter(e => {
        if (+e.ts == +item.ts) {
          if (!item.sub_or) item.sub_or = []

          item.sub_or.push(subitem)
        }
        return e
      })
      this.open_selected_filter_modal(subitem)
      const prev = '' + window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`]
      const next = JSON.stringify(this.filters)
      if (prev != next) {
        this.filter_changed = true
        window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`] = next
      }
    },
    filter_did_updated(item) {
      console.log('>table', item)
      if (item === 'dismiss') {
        // dismiss
        return this.$modal.hide('filter')
      }
      if (item === 'clear') {
        this.$modal.hide('filter')
        return this.clear_filter(this.selected_filter)
      }
      this.$modal.hide('filter')

      if (item.parent_ts) {
        this.filters = this.filters.map(e => {
          if (+e.ts == +item.parent_ts) {
            console.log('found ! ', e.sub_or)
            if(e.sub_or?.length) {
              e.sub_or = e.sub_or.map(l => {
                console.log('check ? ', l.ts, item.ts)
                if (+l.ts == +item.ts) {
                  console.log('check ! ')
                  return item
                }
                return l
              })
            }
          }
          return e
        })
      } else {
        const need_update = this.filters.filter(e => e.ts == item.ts).length
        if (need_update) {
          this.filters = this.filters.map(e => {
            if (e.ts == item.ts) {
              return item
            }
            return e
          })
        } else {
          this.filters.push(item)
        }
      }

      const prev = '' + window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`]
      const next = JSON.stringify(this.filters)
      if (prev != next) {
        this.filter_changed = true
        window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`] = next
      }
      this.load()
    },
    open_filter_modal() {
      this.selected_filter = null
      this.$modal.show('filter')
    },
    open_selected_filter_modal(item) {
      this.selected_filter = item
      this.$modal.show('filter')
    },
    open_modal() {
      // window.sessionStorage['noitaler.use_fullpage'] = 'Y'
      // delete window.sessionStorage['noitaler.use_fullpage']

      if (this.record_row_id) {
        this.$modal.show('record')
      } else {
        this.$modal.hide('record')
      }

      // if (this.record_id) {
      //   if(window.sessionStorage['noitaler.use_fullpage'] === 'Y') {
      //     this.fullpage_record_id = this.record_id
      //   } else {
      //     this.$modal.show('record')
      //   }
      // } else {
      //   if(window.sessionStorage['noitaler.use_fullpage'] === 'Y') {
      //     this.fullpage_record_id = null
      //   } else {
      //     this.$modal.hide('record')
      //   }
      // }
    },
    record_did_loaded() {
      this.record_loaded = true
    },
    record_did_updated({external_id}) {
      this.load()
      if (external_id) {
        if (external_id != this.record_id) {
          this.$router.replace({
            params: {
              record_id: external_id,
            }
          })
        }
      }
    },
    record_tag_did_updated() {
      this.load_tags()
    },
    record_before_close(event) {
      if (this.fullpage_record_id) {
        if (window.sessionStorage['noitaler.use_fullpage'] === 'Y') {
          this.fullpage_record_id = null
          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeText(this.document.name)}`
          })
          return
        }
      }
      if (this.$refs.record_container?.offsetHeight) {
        this.min_height = this.$refs.record_container.offsetHeight + 'px'
      }
      if (!this.record_row_id) {
        // continue close
        return
      }

      event.cancel()
      if (this.record_row_id) {
        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeText(this.document.name)}`
        })
      }
    },
    async load_tags() {
      const r3 = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/tags`)
      if (r3?.data?.message != 'ok') throw new Error('태그 불러오기 실패: ' + r3?.data?.message)
      const tags = r3.data.rows
      this.tags = uniqBy(tags, 'name')
      const tags_by_record_id = {}
      for (const tag of tags) {
        if (!tags_by_record_id[tag.record_id]) tags_by_record_id[tag.record_id] = []
        tags_by_record_id[tag.record_id].push(tag)
      }
      this.tags_by_record_id = tags_by_record_id
      this.tags_count = this.tags.length
      return
    },
    async load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }
      try {
        console.log('saved_filter_id', this.saved_filter_id)
        console.log('saved_filter', this.saved_filter, this.saved_filter.id)
        // setTimeout(() => {
        //   console.log('saved_filter_id', this.saved_filter_id)
        //   console.log('saved_filter', this.saved_filter, this.saved_filter.id)
        // }, 1000);
        if (this.saved_filter_id) {
          // not loaded, should load
          if (!this.saved_filter.id) {
            // http://localhost:8080/property/3/customer/1-23/이루리-멤버십
            const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters/${this.saved_filter_id}`)
            if (r?.data?.message != 'ok' || !r?.data?.row?.id) {
              this.$modal.show('dialog', {
                title: '알림',
                text: '필터를 가져오지 못했습니다.',
              })
            } else {
              this.saved_filter = r.data.row
              this.filters = r.data.row.search_config

              this.$emit('filter_updated', this.saved_filter)
            }
          }
        } else {
          // load local filter
          const saved_filter = window?.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`]
          if (saved_filter) {
            try {
              this.filters = JSON.parse(saved_filter)
            } catch (error) {
              console.log('failed to parse', saved_filter)
            }
          }
        }

        if (!this.saved_filter.id) {
          this.$emit('filter_updated', {})
        }

        if (!this.sort_loaded) {
          const sort = window?.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`]
          if (sort) {
            try {
              const opt = JSON.parse(sort)
              this.sort = opt.sort
              this.sortby = opt.sortby
              this.sort_loaded = true
            } catch (error) {
              console.log('failed to parse', sort)
            }
          }
        }

        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
          params: {
            filters: JSON.stringify(this.filters),
            sort: this.sort,
            sortby: this.sortby,
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          if (m.includes('(filter)')) {
            // reset filter
            window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.filter`] = undefined
          }
          throw new Error('불러오기 실패: '+ r?.data?.message)
        }
        this.rows = r.data.rows.map(e => {
          e.json = JSON.parse(e.json) || {}
          e.is_selected = false
          return e
        })
        this.rows_count = r.data.rows_count
        this.rows_selected_count = 0

        this.cols_ready = true
        this.cols_primary = []
        this.cols_secondary = []
        const cols_by_key = {}

        for (const col of this.document.config.cols) {
          if (col.position == 'primary') {
            this.cols_primary.push(col.key)
          }
          if (col.position == 'secondary') {
            this.cols_secondary.push(col)
          }

          if (!this.cols_by_key_loaded) {
            cols_by_key[col.key] = col
          }
        }
        if (!this.cols_by_key_loaded) {
          this.cols_by_key = cols_by_key
          this.cols_by_key_loaded = true
        }

        if (this.tags_count === null) {
          await this.load_tags()
        }

        this.saving_text = '저장'
        this.done = true

        if (this.record_id) {
          this.open_modal()
        }
        // this.open_filter_modal()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async add_document() {
      try {
        this.adding = true
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객목록 추가 실패')
        this.load()
        setTimeout(() => {
          this.adding = false

        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    row_updated() {
      console.log('reload from row')
      // this.tags_count = null
      this.load()
    }
  },
}
</script>
